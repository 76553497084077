'use strict';

import { FetchError, fetchJSON } from './fetch';
import { show_banner, hide_banner } from './banner';

const PORTAL_MOTD_CHECK_INTERVAL = 10000;
let interval;

document.addEventListener("DOMContentLoaded", function(e) {
  check_portal_motd();
  interval = setInterval(check_portal_motd, PORTAL_MOTD_CHECK_INTERVAL);
});

function check_portal_motd(response) {
  if (document.visibilityState === 'hidden') {
    // when page is not viewed, no need to check motd
    return;
  }

  fetchJSON('/portal/motd/')
    .then(function(data) {
      if (!data || !data.message) {
        hide_banner();
      } else {
        show_banner(data);
      }
    })
    .catch(err => {
      if (err instanceof FetchError) {
        clearInterval(interval);
        show_banner({'type': 'danger', 'message': 'Failed to connect to server'}, true);
      }
    });
}
