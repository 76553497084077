'use strict';

import 'bootstrap/js/dist/modal';
import bootbox from 'bootbox';
import Cookies from 'js-cookie';

/* jshint ignore:start */
const platform_settings = JSON.parse(
  document.getElementById("platform-settings")?.textContent ?? "{}"
);

const csrf_cookie = Cookies.get(platform_settings.csrf_cookie_name ?? "csrftoken");
/* jshint ignore:end */
/* global platform_settings,csrf_cookie */

function hijack() {
  bootbox.prompt('Username:', function(username) {
    if (username === null) {
      return;
    }

    if (username.length < 1) {
      return;
    }

    fetch(`/hijack/username/${username}/`, {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrf_cookie,
        },
      })
      .then(function(data) {
        console.log('LOGGED IN AS', username);
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
        bootbox.alert(error);
      });
  });
}


document.addEventListener('DOMContentLoaded', function(e) {
  document.querySelector('#superpower-hijack')?.addEventListener('click', hijack); // jshint ignore:line
});
